.alertIcon {
  color: #CC0C39;
  transform: scale(0.85);
}

.checkMarkIcon {
  color: #98C91E;
  transform: scale(1.5);
}

.trainedRolesCell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.rowColorYellow {
  border-top-color: #FFA500;
  border-top-width: 15px !important;
  border-top-style: solid;
  background-color: #dad8d8;
  horiz-align: center;
}

.rowColorGreen {
  border-top-color: #008000;
  border-top-width: 15px !important;
  border-top-style: solid;
  background-color: #dad8d8;
  horiz-align: center;
}

.rowColorRed {
  border-top-color: #ff0000;
  border-top-width: 15px !important;
  border-top-style: solid;
  background-color: #dad8d8;
  horiz-align: center;
}

.rowColorGrey {
  border-top-color: #808080;
  border-top-width: 15px !important;
  border-top-style: solid;
  background-color: #dad8d8;
  horiz-align: center;
}

.statusTextGreen {
  color: #008000;
  transform: scale(2.0);
}

.statusTextRed {
  color: #ff0000;
  transform: scale(2.0);
}

.statusTextYellow {
  color: #FFA500;
  transform: scale(2.0);
}

.statusTextGrey {
  color: #808080;
  transform: scale(2.0);
}

.dateTextGrey {
  color: #808080;
  transform: scale(1.0);
}