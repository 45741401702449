h1 {
  font-size: 5vw;
}

.nodeId {
  font-weight: 700;
  color: #ff8f00;
}

.fastStart {
  font-weight: 300;
}