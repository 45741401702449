.welcomeText {
  color: #01222d;
  font-size: 2vw;
  text-align: center;
}

.roleName {
  color: #01222d;
  font-size: 4.5vh;
  font-weight: 500;
  text-align: center;
  margin-top: 0;
  white-space: nowrap;
}