.contentContainer {
  margin: 20px;
  height: 100%;
}

.titleNodeId {
  color: #ff8f00;
  font-weight: 600;
  font-size: 36px;
}

.subTitle {
  color: #000000;
  font-size: 24px;
  font-weight: 400;
}