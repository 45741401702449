.textColor {
    color: #000000;
    alignment: right;
    width: auto;
}

.meridian-override-upload-box p {
    min-width:450px;
    text-align:center;
    font-size:14px;
    position:relative;
    top:-20px;
}

.meridian-override-row-button {
    min-width:470px;
}

.meridian-override-row-button button{
   float:right;
}

div[role=region] {
    background-color:#DAF0FF
}

div[role=region] button {
    font-weight:600;
    color:#000000;
    alignment: center;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    height:40px;

}

.react-datepicker__input-container input[placeholder] {
    font-weight:400;
    color: #232F3E;
    font-size: 16px;
    color-scheme: light;
    line-height: 20px;
    padding-left:10px;
    font-family: "Amazon Ember","Amazon Ember Arabic",Arial,sans-serif;
}