.errorMessage {
  color: #cc0c39;
  font-size: 3vw;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0;
}

.callToAction {
  color: #01222d;
  font-size: 2vw;
  text-align: center;
}