$colors: ( // map to Meridian Grey-300
  siteSpecific: #bbc0c1,
  // map to Meridian Green-500
  LMS: #98c91e,
  // map to Meridian Tangerine-200
  noCalmCode: #ffcb96);

@mixin icon-color-modifiers {

  @each $name,
  $hex in $colors {
    &-#{$name} {
      background-color: $hex;
      width: 18px;
      height: 18px;
      border: 1px solid grey;
    }
  }
}

.colorExampleIcon {
  @include icon-color-modifiers;
}

@mixin radio-button-color-modifiers {

  @each $name,
  $hex in $colors {
    &-#{$name} {
      background-color: $hex;
      border: 1px solid grey;
      width: 335px;
      min-width: 335px;
      height: 50px;
      display: flex;
      justify-content: left;
      padding-left: 20px;
      align-items: center;
    }
  }
}

.radioButtonContainer {
  @include radio-button-color-modifiers;
}